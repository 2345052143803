import ApiService from "../api";

class HistoricoService extends ApiService {

    constructor(){
        super('/portal/historico')
    }

    listar(params){
        return this.post('/listarHistorico', params)
    }

    salvar(params){
        return this.post('/salvarHistorico', params)
    }

   

}

export default HistoricoService;