import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment'
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';

import styled from "styled-components";

const HeaderTable = styled.div`
    text-align:center;
    color:#223a77;
    font-size:24px;
`

const IconHeader = styled(CurrencyExchangeTwoToneIcon)`
    vertical-align:text-bottom;
    margin-right:10px;
    width: 30px !important;
    height: 30px !important;
`

const HeaderTitulo = styled.span`
    vertical-align:text-bottom;
    margin-bottom:10px;
`

const TabelaReembolsos = (props) => {
    const [reembolsos, setReembolsos] = useState([]);
    const [tipoReembolso, setTipoReembolso] = useState("TERRESTRE")
    const [totalTarifaReembolso, setTotalTarifaReembolso] = useState(0.00);
    const [totalTaxaReembolso, setTotalTaxaReembolso] = useState(0.00);
    const [totalCartaoReembolso, setTotalCartaoReembolso] = useState(0.00);
    const [totalComissaoReembolso, setTotalComissaoReembolso] = useState(0.00);
    const [totalIncentivoReembolso, setTotalIncentivoReembolso] = useState(0.00);
    const [totalDescontoReembolso, setTotalDescontoReembolso] = useState(0.00);
    const [totalMultaReembolso, setTotalMultaReembolso] = useState(0.00);
    const [totalLiquidoReembolso, setTotalLiquidoReembolso] = useState(0.00);

    useEffect(() => {
        setReembolsos(props.fatura.reembolsos);
        setTipoReembolso(props.tipoReembolso);
        setTotalTarifaReembolso(formatNumberPosMount(props.fatura.totalTarifaReembolso));
        setTotalTaxaReembolso(formatNumberPosMount(props.fatura.totalTaxaReembolso));
        setTotalCartaoReembolso(formatNumberPosMount(props.fatura.totalCartaoReembolso));
        setTotalComissaoReembolso(formatNumberPosMount(props.fatura.totalComissaoReembolso));
        setTotalIncentivoReembolso(formatNumberPosMount(props.fatura.totalIncentivoReembolso));
        setTotalDescontoReembolso(formatNumberPosMount(props.fatura.totalDescontoReembolso));
        setTotalMultaReembolso(formatNumberPosMount(props.fatura.totalMultaReembolso));
        setTotalLiquidoReembolso(formatNumberPosMount(props.fatura.totalLiquidoReembolso));

    }, [reembolsos, totalTarifaReembolso, totalTaxaReembolso, totalCartaoReembolso,
        totalComissaoReembolso, totalIncentivoReembolso, totalDescontoReembolso, totalMultaReembolso,
        totalLiquidoReembolso, props.fatura.reembolsos, props.fatura.totalTarifaReembolso,
        props.fatura.totalTaxaReembolso, props.fatura.totalCartaoReembolso, props.fatura.totalComissaoReembolso,
        props.fatura.totalIncentivoReembolso, props.fatura.totalDescontoReembolso, props.fatura.totalMultaReembolso,
        props.fatura.totalLiquidoReembolso, props.tipoReembolso]);

    const formatNumberPosMount = (number) => {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const formatNumber = new Intl.NumberFormat('pt-BR', options)
        return number !== "undefined" ? formatNumber.format(number) : 0.00;
    }

    const header = (
        <HeaderTable>
            <IconHeader /><HeaderTitulo>REEMBOLSOS - ADMs - ACMs</HeaderTitulo>
        </HeaderTable>
    );

    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const formatNumber = new Intl.NumberFormat('pt-BR', options)

    const tarifaBodyTemplate = (rowData) => {
        let value = rowData.tarifa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const taxaBodyTemplate = (rowData) => {
        let value = rowData.taxa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }
    const cartaoBodyTemplate = (rowData) => {
        let value = rowData.cartao;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const comissaoBodyTemplate = (rowData) => {
        let value = rowData.comissao;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const incentivoBodyTemplate = (rowData) => {
        let value = rowData.incentivo;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const descontoBodyTemplate = (rowData) => {
        let value = rowData.desconto;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const liquidoBodyTemplate = (rowData) => {
        let value = rowData.liquido;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const multaBodyTemplate = (rowData) => {
        let value = rowData.multa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }
    const dataEmissaoBodyTemplate = (rowData) => {
        return moment(rowData.dataEmissao).format('DD/MM/YYYY')
    }

    const paxBodyTemplate = (rowData) => {
        let value = rowData.passageiro;
        var passageiros = value.split("%");
        return (
            <div className="flex flex-wrap gap-2">
                {passageiros.map(pax => <><span>{pax}</span><br></br></>)}
            </div>
        );
    }

    const reactFooter = (data) => {
        return (
            <React.Fragment>
                <td colSpan={11}>Observação: {data.obs}</td>
            </React.Fragment>
        );
    }
    return (
        <DataTable
            value={reembolsos}
            header={header}
            rowGroupMode="subheader"
            groupRowsBy="numreemb"
            scrollable
            scrollHeight="500px"
            tableStyle={{ minWidth: '1790px' }}
            size="small" sortMode="single"
            sortOrder={1}
            rowGroupFooterTemplate={reactFooter}
            stripedRows >

            <Column style={{ minWidth: '400px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field={paxBodyTemplate} header="PASSAGEIRO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field={dataEmissaoBodyTemplate} header="EMISSÃO"></Column>
            {tipoReembolso !== "TERRESTRE" ?
                <Column style={{ minWidth: '170px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field="tipodoc" header="TIPO DOC."></Column>
                :
                <Column style={{ minWidth: '170px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field="tipoprod" header="PRODUTO"></Column>
            }
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field="numreemb" header="Nº DOC"></Column>

            {tipoReembolso !== "TERRESTRE" ?
                <Column style={{ minWidth: '220px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}}  footer="Total Geral:" headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field="numtkt" header="TICKET / LOC"></Column>
                :
                <Column style={{ minWidth: '220px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}}  footer="Total Geral:" headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field="numresv" header="Nº RESERVA"></Column>
            }
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}}  footer={totalTarifaReembolso}  headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field={tarifaBodyTemplate} header="TARIFA"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}}  footer={totalTaxaReembolso} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field={taxaBodyTemplate} header="TAXA"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}}  footer={totalMultaReembolso} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field={multaBodyTemplate} header="MULTA"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}}  footer={totalCartaoReembolso} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field={cartaoBodyTemplate} header="CARTÃO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}}  footer={totalComissaoReembolso} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field={comissaoBodyTemplate} header="COMISSÃO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}}  footer={totalIncentivoReembolso} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field={incentivoBodyTemplate} header="INCENTIVO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}}  footer={totalDescontoReembolso} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field={descontoBodyTemplate} header="DESCONTO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}}  footer={totalLiquidoReembolso} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize: "12px" }} field={liquidoBodyTemplate} header="LÍQUIDO"></Column>
        </DataTable>
    );
}

export default TabelaReembolsos;