import { createGlobalStyle } from "styled-components";


export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.MuiDataGrid-columnsContainer{
    background-color:#223a77;
    color:white;
    font-weight:bold;
    font-size:14px;
}
  .p-datatable-table{
    width: 100%;
  }
    
.p-tabview-panels{
  padding:0 !important;
}

.p-column-header-content{
  justify-content: center !important;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon{
  color:white !important;
}

.p-datatable .p-sortable-column .p-sortable-column-icon{
  color:white !important;
}

.p-datatable .p-sortable-column:focus{
 box-shadow:none;
}

body {
  
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  *-moz-osx-font-smoothing: grayscale;
  height:100%;
  display: flex;
	flex-direction: column;
  text-rendering:optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`;



export default GlobalStyle;
