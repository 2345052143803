import React, { useState, useEffect } from 'react';

import TemporaryDrawer from "../../main/TemporayDrawer";
import Footer from "../../main/Footer";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Tag } from 'primereact/tag';
import Button from '@mui/material/Button';
import { Button as ButtonPrime } from 'primereact/button';
import CurrencyExchangeTwoToneIcon from '@mui/icons-material/CurrencyExchangeTwoTone';
import { TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Fab from '@mui/material/Fab';
import ReembolsoService from '../../api/services/ReembolsoService';
import EmpresaService from '../../api/services/EmpresaService';
import { getCodEmpUser, isAdmin } from '../../api/services/AuthService';
import moment from 'moment'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeaderFilterSearch from '../../main/CardHeaderFilterSearch';
import { motion } from "framer-motion";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import BackDrop from '../../backdrop/BackDrop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputMask from 'react-input-mask'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Tooltip from '@mui/material/Tooltip';
import SendIcon from '@mui/icons-material/Send';
import ClearIcon from '@mui/icons-material/Clear';
import FaturaService from '../../api/services/FaturaService';


import styled from "styled-components";

const LinkSolicitarReembolso = styled(Link)`
    float: right;
`

const DialogTitleCliente = styled(DialogTitle)`   
    color:#041E42;
`

const Layout = styled.div`   
    background-color:#FFFF;
    font-weight: bold;
    width:95%;
    min-height:500px;
    top:80px;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    margin-top:100px;
    
`
const CustomDesktopDatePicker = styled(DesktopDatePicker)`
& .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #223a77;
    }
  }
`;
const TabelaModal = styled.div`
    height: 300px;
    overflow: auto;
    width: 100%;
    margin-top:10px;
`
const StackButton = styled(Stack)`
  font-weight: bold;
  width:80%;
  margin-top:10px;
  align-items: center;
`

const Tabela = styled.div`
    min-height: 300px;
    overflow: auto;
    width: 100%;
    padding-top:15px;
`

const Titulo = styled(Typography)`
    color:#223a77;
    font-weight:bold;
    width:100%;
    margin-botton:30px;
`

const MsgTitulo = styled(Typography)`
    color:#223a77;
    font-weight:bold;
    float: left;
`
const Header = styled(Box)`   
    margin-top:10px;
    width:100%;
    height:50px;
`

const ListarReembolso = () => {

    const location = useLocation();

    let navigate = useNavigate();
    const [solicitacao, setSolicitacao] = useState("");
    const [reembolso, setReembolso] = useState("");
    const [localizador, setLocalizador] = useState("");
    const [bilhete, setBilhete] = useState("");
    const [status, setStatus] = useState("TODOS");
    const [solicitacoes, setSolicitacoes] = useState([]);
    const [open, setOpen] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [tipoData, setTipoData] = useState("TODAS");
    const [tipoSnack, setTipoSnack] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [backdropDialog, setBackdropDialog] = useState(false)
    const [nomeEmpresa, setNomeEmpresa] = useState("");
    const [codigoEmpresa, setCodigoEmpresa] = useState("");
    const [empresa, setEmpresa] = useState("");
    const [codigoEmpresaSelecionada, setCodigoEmpresaSelecionada] = useState(sessionStorage.getItem("codigoEmpresaSelecionada") && sessionStorage.getItem("codigoEmpresaSelecionada") !== '' ? sessionStorage.getItem("codigoEmpresaSelecionada") : "");
    const [cnpjEmpresa, setCnpjEmpresa] = useState("");
    const [cpfEmpresa, setCpfEmpresa] = useState("");
    const [empresas, setEmpresas] = useState([]);
    const [empresaSelecionada, setEmpresaSelecionada] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [rowClick, setRowClick] = useState(false);
    const [selectedSolicitacao, setSelectedSolicitacao] = useState(null);
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;

    useEffect(() => {
        if (location && location.state) {
            setRowClick(false);
            setSolicitacoes(location.state.solicitacoes ? location.state.solicitacoes : []);
            setOpenSnack(location.state.snack ? location.state.snack : false)
            setTipoSnack("success");
            setErrorMessage("Solicitações salvas com sucesso!")
            if (location.state.status) {
                findByStatus(location.state.status)
            }
        } else {
            if (!isAdmin())
                listar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const findByStatus = (lStatus) => {
        let statusSelecionado = '';
        switch (lStatus) {
            case "Solicitadas":
                statusSelecionado = 'SOLICITADO';
                break;
            case "Enviadas Cia":
                statusSelecionado = 'ENVIADO CIA';
                break;
            case "Canceladas":
                statusSelecionado = 'CANCELADO';
                break;
            case "Processadas":
                statusSelecionado = 'PROCESSADO';
                break;
            default:
        }
        setStatus(statusSelecionado);
        listarSimplificado(statusSelecionado);
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const handleView = (event, cellValues) => {
        navigate('/detalharReembolso', { replace: true, state: { solicitacao: selectedSolicitacao } })
    }

    const faturaService = new FaturaService();
    
    const handleViewFatura = () => {
        handleToggle();
        faturaService.detalhar(
            {
                invoiceNumber: selectedSolicitacao.fatura,
                empfat: isAdmin() ? codigoEmpresaSelecionada : getCodEmpUser(),
                dataven: null,
                codest: null,
                invoiceType: 'AEREO',
                condpag: null,
                valor: null,
            }
        ).then(response => {
            navigate('/detalharFatura', { replace: true, state: { fatura: response.data } })
        }).catch(erro => {
            //setError(true);
        }).finally(() => {
            handleClose();
        });
    }

    const reembolsoService = new ReembolsoService();
    const empresaService = new EmpresaService();

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };
    
    const listarSimplificado = (statusSelecionado) => {
        handleToggle();
        reembolsoService.listar({
            status: statusSelecionado,
            tipoDataSolicitacao: 'TODAS',
            empfat: isAdmin() ? codigoEmpresaSelecionada : getCodEmpUser(),
        }).then(response => {
            setSolicitacoes(response.data);
        }).catch(erro => {
            console.log('Error name: ', erro.name)
            console.log('Error message: ', erro.message)
            console.log('Error stack: ', erro.stack)
        }).finally(() => {
            handleClose();
        })
    };

    const selecionarEmpresa = () => {
        if (!empresaSelecionada) {
            setErrorMessage('Nenhum Cliente Selecionado!');
            setOpenSnack(true);
            setTipoSnack("warning")
        } else {
            setEmpresa(empresaSelecionada.codemp + " - " + empresaSelecionada.nome);
            setCodigoEmpresaSelecionada(empresaSelecionada.codemp);
            setOpenDialog(false);
            setOpenSnack(true);
            setTipoSnack("success")
            setErrorMessage('Empresa ' + empresaSelecionada.nome + ' selecionada!');
        }
    }

    const validarListagem = () => {
        let retorno = true;
        let message = '';
        if ((dataInicio || dataFim) && tipoData === 'TODAS') {
            message += "Selecione o Período para pesquisa!\n";
            retorno = false;
        } if (tipoData !== 'TODAS' && (!dataInicio || !dataFim)) {
            message += "Preencha o Data Início e a Data Fim!\n";
            retorno = false;
        }
        if (!retorno) {
            setErrorMessage(message);
            setOpenSnack(true);
            setTipoSnack("warning");
        }
        return retorno;
    }

    const listar = () => {
        if (validarListagem()) {
            handleToggle();
            reembolsoService.listar({
                numtkt: bilhete === '' ? null : bilhete,
                loc: localizador === '' ? null : localizador,
                reembolso: reembolso === '' ? null : reembolso,
                solicitacao: solicitacao === '' ? null : solicitacao,
                status: status === 'TODOS' || status === '' ? null : status,
                codemp: isAdmin() ? codigoEmpresaSelecionada : getCodEmpUser(),
                dataInicio: dataInicio,
                dataFim: dataFim,
                tipoDataSolicitacao: tipoData,
            }).then(response => {
                setSolicitacoes(response.data);
            }).catch(erro => {
                console.log('Error name: ', erro.name)
                console.log('Error message: ', erro.message)
                console.log('Error stack: ', erro.stack)
            }).finally(() => {
                handleClose();
            })
        }
    };

    const handleChangeDataInicio = (newValue) => {
        setDataInicio(newValue);
    };

    const handleChangeDataFim = (newValue) => {
        setDataFim(newValue);
    };

    const clear = () => {
        handleToggle();
        setSolicitacao("");
        setReembolso("");
        setStatus("TODOS");
        setLocalizador("");
        setBilhete("");
        setSolicitacoes([]);
        handleClose();
        setTipoData("TODAS");
        setDataInicio(null);
        setDataFim(null);
    }

    const handleCloseDialog = () => {
        setEmpresaSelecionada("");
        setCodigoEmpresa("");
        setEmpresa("");
        setEmpresas([]);
        setOpenDialog(false);
    };

    const clearModal = () => {
        setEmpresaSelecionada("");
        setCodigoEmpresa("");
        setEmpresas([]);
        setNomeEmpresa("");
        setCnpjEmpresa("");
        setCpfEmpresa("");
    }

    const validarListagemCliente = () => {
        let retorno = true;
        if (!nomeEmpresa && !codigoEmpresa && !cnpjEmpresa && !cpfEmpresa) {
            setErrorMessage("Preencha pelo menos um campo de filtro para realizar a pesquisa de clientes!");
            setOpenSnack(true);

            retorno = false;
        }

        return retorno;
    }

    const findEmpresas = () => {
        if (validarListagemCliente()) {
            setBackdropDialog(true);
            empresaService.listarEmpresas({
                codemp: codigoEmpresa === '' ? null : codigoEmpresa,
                nome: nomeEmpresa === '' ? null : nomeEmpresa,
                cnpj: cnpjEmpresa === '' ? null : cnpjEmpresa.replace(/[^\d]+/g, ''),
                cpf: cpfEmpresa === '' ? null : cpfEmpresa.replace(/[^\d]+/g, ''),
            }).then(response => {
                setEmpresas(response.data);
            }).catch(erro => {
                console.log('Error name: ', erro.name)
                console.log('Error message: ', erro.message)
                console.log('Error stack: ', erro.stack)
            }).finally(() => {
                handleClose();
                setBackdropDialog(false);
            })
        }
    }

    const dataSolicitacaoBodyTemplate = (rowData) => {
        return rowData.data ? moment(rowData.data).format('DD/MM/YYYY') : "-"
    }

    const prazoSolicitacaoBodyTemplate = (rowData) => {
        return rowData.prazo ? moment(rowData.prazo).format('DD/MM/YYYY') : "-"
    }

    const vencimentoSolicitacaoBodyTemplate = (rowData) => {
        return rowData.vencimento ? moment(rowData.vencimento).format('DD/MM/YYYY') : "-"
    }

    const faturaBodyTemplate = (rowData) => {
        return rowData.fatura ? rowData.fatura : "-"
    }

    const reembolsoBodyTemplate = (rowData) => {
        return rowData.reembolso ? rowData.reembolso : "-"
    }

    const situacaoBodyTemplate = (rowData) => {
        return <Tag className="mr-2" rounded value={rowData.situacao} severity={getSituacao(rowData.situacao)} style={{ color: "white" }} />;
    };

    const getSituacao = (status) => {
        switch (status) {
            case 'SOLICITADO':
                return 'info';

            case 'ENVIADO CIA':
                return 'warning';

            case 'PROCESSADO':
                return 'success';

            case 'CANCELADO':
                return 'danger';

            default:
                return;
        }
    };

    return (
        <>
            <TemporaryDrawer />
            <motion.div
                className='reembolso'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>

                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress style={{ color: '#223a77' }} />
                    <CircularProgress color="inherit" />
                    <CircularProgress style={{ color: '#223a77' }} />
                </Backdrop>
                <Layout className="container">
                    <Header>
                        <Titulo>
                            <MsgTitulo variant="h5" gutterBottom component="div">LISTAGEM DE SOLICITAÇÕES DE REEMBOLSO</MsgTitulo>
                            {!isAdmin() ?
                                <LinkSolicitarReembolso to='/cadastrarReembolso' title="Solicitar Reembolsos">
                                    <Fab sx={{ zIndex: 0, marginRight: 0 }} variant="contained" color="primary" aria-label="add" size="small">
                                        <CurrencyExchangeTwoToneIcon />
                                    </Fab>
                                </LinkSolicitarReembolso>
                                : null}
                        </Titulo>
                    </Header>
                    <Card sx={{ minWidth: 1000, backgroundColor: "#F5F5F5", flexGrow: 1 }}>
                        <CardContent>
                            <CardHeaderFilterSearch />
                            <Box component="form">
                                <TextField
                                    id="solicitacao"
                                    sx={{ width: 140, backgroundColor: "#FFFF" }}
                                    label="Nº Solicitação"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={solicitacao}
                                    inputProps={{
                                        maxLength: 8,
                                        inputProps: { min: 1, max: 9 }
                                    }}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setSolicitacao(e.target.value)
                                        }
                                    }}
                                />
                                <TextField
                                    id="Reembolso"
                                    sx={{ width: 140, backgroundColor: "#FFFF", marginLeft: 2 }}
                                    label="Nº Reembolso"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={reembolso}
                                    inputProps={{
                                        maxLength: 9,
                                        inputProps: { min: 1, max: 9 }
                                    }}
                                    onChange={(e) => {
                                        const re = /^[0-9\b]+$/;
                                        if (e.target.value === '' || re.test(e.target.value)) {
                                            setReembolso(e.target.value)
                                        }
                                    }}
                                />
                                <TextField
                                    id="localizador"
                                    sx={{ width: 140, backgroundColor: "#FFFF", marginLeft: 2 }}
                                    width="50"
                                    label="Localizador"
                                    type="text"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={localizador}
                                    onChange={(event) => {
                                        setLocalizador(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="bilhete"
                                    sx={{ width: 180, backgroundColor: "#FFFF", marginLeft: 2 }}
                                    label="Bilhete"
                                    type="text"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={bilhete}
                                    onChange={(event) => {
                                        setBilhete(event.target.value);
                                    }}
                                />
                                <FormControl sx={{ m: 1, width: 180, marginLeft: 2, marginTop: 2 }} variant="outlined">
                                    <InputLabel id="status-label">Status</InputLabel>
                                    <Select
                                        labelId="status-label"
                                        id="status"
                                        value={status}
                                        label="Status"
                                        sx={{ backgroundColor: "#FFFF" }}
                                        size="small"
                                        onChange={(event) => {
                                            setStatus(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={'TODOS'}>Selecione</MenuItem>
                                        <MenuItem value={'SOLICITADO'}>Solicitado</MenuItem>
                                        <MenuItem value={'ENVIADO CIA'}>Enviado Cia</MenuItem>
                                        <MenuItem value={'CANCELADO'}>Cancelado</MenuItem>
                                        <MenuItem value={'PROCESSADO'}>Processado</MenuItem>

                                    </Select>
                                </FormControl>
                            </Box>
                            <Box component="form">
                                <FormControl sx={{ width: 200, marginTop: 2 }} variant="outlined">
                                    <InputLabel id="labelSelectTipoDatas">Período</InputLabel>
                                    <Select
                                        labelId="labelSelectTipoDatas"
                                        id="selectTipoDatas"
                                        value={tipoData}
                                        label="periodo"
                                        size="small"
                                        sx={{ backgroundColor: "#FFFF" }}
                                        onChange={(event) => {
                                            setTipoData(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={'TODAS'}>Selecione</MenuItem>
                                        <MenuItem value={'DATA_SOLICITACAO'}>Data Solicitação</MenuItem>
                                        <MenuItem value={'DATA_CIA'}>Data Cia</MenuItem>
                                    </Select>
                                </FormControl>
                                <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs} >
                                    <CustomDesktopDatePicker
                                        label="Data Início"
                                        inputFormat="DD/MM/YYYY"
                                        value={dataInicio}
                                        onChange={handleChangeDataInicio}
                                        renderInput={(params) => <TextField size="small" sx={{ marginTop: 2, marginLeft: 2.5, backgroundColor: "#FFFF" }}  {...params} />}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs} >
                                    <CustomDesktopDatePicker
                                        label="Data Fim"
                                        inputFormat="DD/MM/YYYY"
                                        value={dataFim}
                                        onChange={handleChangeDataFim}
                                        renderInput={(params) => <TextField size="small" sx={{ marginTop: 2, marginLeft: 2.5, backgroundColor: "#FFFF" }}  {...params} />}
                                    />
                                </LocalizationProvider>
                                {isAdmin() ?
                                    <TextField
                                        id="empresaAdmin"
                                        sx={{ width: 500, backgroundColor: "#FFFF", marginLeft: 2.5 }}
                                        label="Empresa"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={empresa}
                                        onChange={(event) => {
                                            setEmpresa(event.target.value.replace(/[^0-9.]/g, ''));
                                        }}
                                    />
                                    : null}
                                {isAdmin() ?
                                    <Tooltip title="Clique Para Pesquisar Agência!">
                                        <Fab onClick={handleClickOpen} sx={{ m: 1, marginTop: 2, marginLeft: 2 }} variant="contained" color="primary" aria-label="add" size="small">
                                            <AddBusinessIcon />
                                        </Fab>
                                    </Tooltip>
                                    : null}
                            </Box>
                            <StackButton spacing={2} direction="row" sx={{ paddingTop: 1 }}>
                                <Button startIcon={<SendIcon />} sx={{ width: 100 }} size="small" onClick={listar} variant="contained" color="primary">Listar</Button>
                                <Button startIcon={<ClearIcon />} sx={{ width: 100 }} size="small" onClick={clear} variant="contained" color="primary">Limpar</Button>
                            </StackButton>
                        </CardContent>
                    </Card>
                    {(solicitacoes && solicitacoes.length > 0) || !isAdmin() ?
                        <Card sx={{ minWidth: 400, backgroundColor: "#F5F5F5", flexGrow: 1, marginTop: 2, minHeight: 300 }}>
                            <CardContent>
                                <div className="card flex flex-wrap justify-content-center gap-3">
                                    <ButtonPrime disabled={!selectedSolicitacao} onClick={handleView} style={{ backgroundColor: "#223a77", float: "left" }} type="button" label="Detalhar" icon="pi pi-eye" />
                                    <ButtonPrime className="p-button-secondary" disabled={!selectedSolicitacao || !selectedSolicitacao.fatura || selectedSolicitacao.fatura === '-'} onClick={handleViewFatura} style={{ marginLeft: 3, float: "left" }} type="button" label="Fatura" icon="pi pi-file" />
                                    <Typography variant="h7" gutterBottom style={{ color: "#223a77", float: "right", marginTop: 5 }}>{selectedSolicitacao ? "SOLICITAÇÃO SELECIONADA: " + selectedSolicitacao.idseq : "NENHUMA SOLICITAÇÃO SELECIONADA! "}</Typography>
                                </div>
                                <Tabela>
                                    <DataTable
                                        value={solicitacoes}
                                        selectionMode={rowClick ? null : 'radiobutton'}
                                        selection={selectedSolicitacao}
                                        responsiveLayout="scroll"
                                        emptyMessage="Nenhuma Solicitação Encontrada"
                                        paginator
                                        rows={10}
                                        style={{ color: "#223a77" }}
                                        sortMode="multiple"
                                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                                        currentPageReportTemplate="{first} a {last} de {totalRecords}"
                                        paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
                                        onSelectionChange={(e) => setSelectedSolicitacao(e.value)}
                                        dataKey="idseq">
                                        <Column selectionMode="single" headerStyle={{ backgroundColor: '#223a77', color: 'white', width: '3em' }}></Column>
                                        <Column field="idseq" bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#223a77', color: 'white' }} header="Solicitação"></Column>
                                        <Column field="reembolso" body={reembolsoBodyTemplate} bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#223a77', color: 'white' }} header="Reembolso"></Column>
                                        <Column field="data" body={dataSolicitacaoBodyTemplate} bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#223a77', color: 'white' }} header="Data"></Column>
                                        <Column field="prazo" body={prazoSolicitacaoBodyTemplate} bodyStyle={{ textAlign: "right" }} sortable headerStyle={{ backgroundColor: '#223a77', color: 'white' }} header="Prazo"></Column>
                                        <Column field="fatura" body={faturaBodyTemplate} bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#223a77', color: 'white' }} header="Fatura"></Column>
                                        <Column field="vencimento" body={vencimentoSolicitacaoBodyTemplate} bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#223a77', color: 'white' }} header="Vencimento"></Column>
                                        <Column field="numtkt" bodyStyle={{ textAlign: "center" }} headerStyle={{ backgroundColor: '#223a77', color: 'white' }} header="Bilhete"></Column>
                                        <Column field="loc" bodyStyle={{ textAlign: "center" }} headerStyle={{ backgroundColor: '#223a77', color: 'white' }} header="Loc"></Column>
                                        <Column field="pax" bodyStyle={{ textAlign: "left" }} sortable headerStyle={{ backgroundColor: '#223a77', color: 'white', width: "20%" }} header="Pax"></Column>
                                        <Column field="situacao" body={situacaoBodyTemplate} bodyStyle={{ textAlign: "center" }} sortable headerStyle={{ backgroundColor: '#223a77', color: 'white' }} header="Situação"></Column>
                                    </DataTable>
                                </Tabela>
                            </CardContent>
                        </Card>
                        : null}
                </Layout>

                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity={tipoSnack} sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </motion.div >
            <Footer />
            <Dialog maxWidth="lg" fullWidth open={openDialog} onClose={handleCloseDialog}>
                <BackDrop onOpen={backdropDialog}></BackDrop>
                <DialogTitleCliente>PESQUISA DE CLIENTES</DialogTitleCliente>
                <DialogContent >
                    <TextField
                        autoFocus
                        margin="dense"
                        sx={{ width: 100 }}
                        id="codigo"
                        label="Código"
                        fullWidth
                        variant="standard"
                        value={codigoEmpresa}
                        onChange={(event) => {
                            setCodigoEmpresa(event.target.value);
                        }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="empresa"
                        label="Empresa"
                        sx={{ width: 400, marginLeft: 2 }}
                        fullWidth
                        variant="standard"
                        value={nomeEmpresa}
                        onChange={(event) => {
                            setNomeEmpresa(event.target.value);
                        }}
                    />
                    <InputMask mask="99.999.999/9999-99" value={cnpjEmpresa} onChange={(event) => {
                        setCnpjEmpresa(event.target.value);
                    }}>
                        {
                            inputProps => (
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="cnpj"
                                    sx={{ width: 200, marginLeft: 2 }}
                                    label="CNPJ"
                                    fullWidth
                                    variant="standard"
                                />
                            )
                        }
                    </InputMask>
                    <InputMask mask="999.999.999-99" value={cpfEmpresa} onChange={(event) => {
                        setCpfEmpresa(event.target.value);
                    }}>
                        {
                            inputProps => (
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="cpf"
                                    sx={{ width: 200, marginLeft: 2 }}
                                    label="CPF"
                                    fullWidth
                                    variant="standard"
                                />
                            )
                        }
                    </InputMask>

                    <Button sx={{ marginTop: 3, marginLeft: 2 }} color="primary" onClick={clearModal}>Limpar</Button>
                    <Button sx={{ marginTop: 3, marginLeft: 2 }} color="primary" onClick={findEmpresas}>Pesquisar</Button>
                    <TabelaModal>
                        <DataTable value={empresas}
                            selection={empresaSelecionada}
                            paginator rows={10}
                            onSelectionChange={e =>
                                setEmpresaSelecionada(e.value)
                            } dataKey="codemp"
                            responsiveLayout="scroll"
                        >
                            <Column selectionMode="single" headerStyle={{ width: '3em' }}></Column>
                            <Column field="codemp" header="Código"></Column>
                            <Column field="nome" header="Nome"></Column>
                            <Column field="cnpj" header="CNPJ"></Column>
                            <Column field="cpf" header="CPF"></Column>
                        </DataTable>
                    </TabelaModal>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleCloseDialog}>Cancelar</Button>
                    {empresas.length > 0 ?
                        <Button color="primary" onClick={selecionarEmpresa}>Selecionar</Button>
                        : null}
                </DialogActions>
            </Dialog>
        </>
    );

};

export default ListarReembolso;