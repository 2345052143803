import React from 'react';

import styled from "styled-components";
import Typography from '@mui/material/Typography';


const DivFooter = styled.footer`
    width: 100%;
    padding: 1px 0;
    background-color: #223a77;
    border-top: 4px solid #20B2AA;
    font-size: 12px;
    color: white;
    bottom: 0;

    position: fixed;
    width: 100%;
    text-align: center;
    left:0;
    z-index: 1;
`;

const Cpright = styled(Typography)`
    text-align:center;
    font-weight:bold !important;
`

const Footer =() => {
    return (
        <DivFooter>
            <Cpright variant="body2" gutterBottom component="div">
                Copyright © 2022 Coinfo. Todos os direitos reservados.
            </Cpright>
        </DivFooter>
    );
};

export default Footer;