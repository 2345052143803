import React from 'react';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import SearchIcon from '@mui/icons-material/Search';


const CardHeaderFilterSearch = () => {
    return (
        <CardHeader
            sx={{ height: '18px', paddingLeft: 0}}
            titleTypographyProps={{ fontSize: "20px", color: "#223a77" }}
            title="FILTROS DE BUSCA"
            avatar={
                <Avatar variant="square" sx={{ backgroundColor: "#223a77", width: "24px", height: "24px" }} aria-label="recipe">
                    <SearchIcon />
                </Avatar>
            }
        >
        </CardHeader>
    );
};

export default CardHeaderFilterSearch;
