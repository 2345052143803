const addItemFromJson = (key, json) =>{
    localStorage.setItem(key, JSON.stringify(json));
};

const addItemFromObject = (key, object) =>{
    localStorage.setItem(key, object);
};

const getItem = (key) =>{
    return JSON.parse(localStorage.getItem(key));
}

const removeItem = (key) =>{
    return localStorage.removeItem(key);
}

const clearStorage = () => {
    localStorage.clear();
}

const getItemStorage = (key) =>{
    return localStorage.getItem(key);
}

export{addItemFromJson, addItemFromObject, getItem, removeItem, clearStorage, getItemStorage};