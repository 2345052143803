import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment'
import styled from "styled-components";
import SecurityIcon from '@mui/icons-material/Security';

const SpanTitle = styled.span`
    font-size:16px;
    color:#223a77;
    font-weight:bold;
`
const SpanData = styled.span`
    margin-right:50px;
    font-size:16px;
    color:#223a77;
    font-weight:bold;
    display:inline-block;
    width:150px;
`
const FooterGroup = styled.p`
    text-align:right;
    font-size:15px;
    color:#223a77;
    font-weight:bold;
`
const HeaderTable = styled.div`
    text-align:center;
    color:#223a77;
    font-size:24px;
`

const IconHeader = styled(SecurityIcon)`
    vertical-align:text-bottom;
    margin-right:10px;
    width: 30px !important;
    height: 30px !important;
`

const HeaderTitulo = styled.span`
    margin-bottom:10px;
`


const TabelaSeguros = (props) => {
    const [servicos, setServicos] = useState([]);
    const [totalDiaria, setTotalDiaria] = useState(0.00);
    const [totalTotalizadorDiaria, setTotalTotalizadorDiaria] = useState(0.00);
    const [totalTaxa, setTotalTaxa] = useState(0.00);
    const [totalCartao, setTotalCartao] = useState(0.00);
    const [totalComissao, setTotalComissao] = useState(0.00);
    const [totalIncentivo, setTotalIncentivo] = useState(0.00);
    const [totalDesconto, setTotalDesconto] = useState(0.00);
    const [totalFee, setTotalFee] = useState(0.00);
    const [totalLiquido, setTotalLiquido] = useState(0.00);
    const [totalExtras, setTotalExtras] = useState(0.00);

    useEffect(() => {
        setServicos(props.fatura.seguros);
        setTotalDiaria(formatNumberPosMount(props.fatura.totalDiariaSeguro));
        setTotalTaxa(formatNumberPosMount(props.fatura.totalTaxaSeguro));
        setTotalCartao(formatNumberPosMount(props.fatura.totalCartaoSeguro));
        setTotalComissao(formatNumberPosMount(props.fatura.totalComissaoSeguro));
        setTotalIncentivo(formatNumberPosMount(props.fatura.totalIncentivoSeguro));
        setTotalDesconto(formatNumberPosMount(props.fatura.totalDescontoSeguro));
        setTotalFee(formatNumberPosMount(props.fatura.totalFeeSeguro));
        setTotalLiquido(formatNumberPosMount(props.fatura.totalLiquidoSeguro));
        setTotalTotalizadorDiaria(formatNumberPosMount(props.fatura.totalTotalizadorDiariaSeguro));
        setTotalExtras(formatNumberPosMount(props.fatura.totalExtrasSeguro));
    }, [props.fatura.seguros, props.fatura.totalCartaoSeguro, props.fatura.totalComissaoSeguro, props.fatura.totalDescontoSeguro, props.fatura.totalDiariaSeguro, 
        props.fatura.totalExtrasSeguro, props.fatura.totalFeeSeguro, props.fatura.totalIncentivoSeguro, props.fatura.totalLiquidoSeguro, 
        props.fatura.totalTaxaSeguro, props.fatura.totalTotalizadorDiariaSeguro]);


    const formatNumberPosMount = (number) => {
        const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        const formatNumber = new Intl.NumberFormat('pt-BR', options)
        return number !== "undefined" ? formatNumber.format(number) : 0.00;
    }

    const header = (
        <HeaderTable>
            <IconHeader /><HeaderTitulo>SEGUROS</HeaderTitulo>
        </HeaderTable>
    );

    const reactFooter = (totalizadores) => {
        return (
            <React.Fragment>
                <td style={{ minWidth: '400px' }}></td>
                <td style={{ minWidth: '150px' }}></td>
                <td style={{ minWidth: '100px' }}></td>
                <td style={{ minWidth: '200px' }}></td>
                <td style={{ minWidth: '100px' }}></td>
                <td style={{ minWidth: '100px' }}></td>
                <td style={{ minWidth: '100px' }}></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>Total:</FooterGroup></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>{totalizadores.diaria}</FooterGroup></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>{totalizadores.totalDiaria}</FooterGroup></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>{totalizadores.totalTaxa}</FooterGroup></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>{totalizadores.extras}</FooterGroup></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>{totalizadores.cartao}</FooterGroup></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>{totalizadores.incentivo}</FooterGroup></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>{totalizadores.comissao}</FooterGroup></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>{totalizadores.desconto}</FooterGroup></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>{totalizadores.fee}</FooterGroup></td>
                <td style={{ minWidth: '100px' }}><FooterGroup>{totalizadores.liquido}</FooterGroup></td>
            </React.Fragment>
        );
    }

    const footerTemplate = (data) => {
        let totalizadores = countCarroslTotal(data.grupoCarro);

        return reactFooter(totalizadores);
    }

    const headerTemplate = (data) => {
        return (
            <React.Fragment>
                <SpanTitle>Emissão:&nbsp;</SpanTitle><SpanData>{data.fpagtoFormatado}</SpanData>
                <SpanTitle>Tipo:&nbsp;</SpanTitle><SpanData>{data.tipoFormatado}</SpanData>
            </React.Fragment>
        );
    }

    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const formatNumber = new Intl.NumberFormat('pt-BR', options)

    const extrasBodyTemplate = (rowData) => {
        let value = rowData.valextcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const cartaoBodyTemplate = (rowData) => {
        let value = rowData.valcrtcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const comissaoBodyTemplate = (rowData) => {
        let value = rowData.valrpcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const incentivoBodyTemplate = (rowData) => {
        let value = rowData.valcmcl;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const descontoBodyTemplate = (rowData) => {
        let value = rowData.valdesc;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const liquidoBodyTemplate = (rowData) => {
        let value = rowData.valarec;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const feeBodyTemplate = (rowData) => {
        let value = rowData.txadcli;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const taxaBodyTemplate = (rowData) => {
        let value = rowData.taxa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const totalDiariasBodyTemplate = (rowData) => {
        let value = rowData.tarifa;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const diariaBodyTemplate = (rowData) => {
        let value = rowData.tarifpro;
        var formattedMoney = formatNumber.format(value);
        return formattedMoney;
    }

    const paxBodyTemplate = (rowData) => {

        let value = rowData.pax;
        var passageiros = value.split("%");
        return (
            <div className="flex flex-wrap gap-2">
                {passageiros.map(pax => <><span>{pax}</span><br></br></>)}
            </div>
        );
    }

    const dataEmissaoBodyTemplate = (rowData) => {
        return moment(rowData.emissao).format('DD/MM/YYYY')
    }

    const checkinBodyTemplate = (rowData) => {
        return moment(rowData.datain).format('DD/MM/YYYY')
    }

    const checkoutBodyTemplate = (rowData) => {
        return moment(rowData.dataout).format('DD/MM/YYYY')
    }

    const countCarroslTotal = (grupo) => {
        let totalDiaria = 0;
        let totalTotalizadorDiaria = 0;
        let totalTaxa = 0;
        let totalExtras = 0;
        let totalCartao = 0;
        let totalIncentivo = 0;
        let totalComissao = 0;
        let totalDesconto = 0;
        let totalFee = 0;
        let totalLiquido = 0;

        if (servicos) {
            for (let servico of servicos) {
                if (servico === undefined || servico.grupoServico === grupo) {
                    totalDiaria += servico.tarifpro;
                    totalTotalizadorDiaria += servico.tarifa;
                    totalTaxa += servico.taxa;
                    totalCartao += servico.valcrtcl;
                    totalExtras += servico.valextcl;
                    totalIncentivo += servico.valcmcl;
                    totalComissao += servico.valrpcl;
                    totalDesconto += servico.valdesc;
                    totalFee += servico.txadcli;
                    totalLiquido += servico.valarec;
                }
            }
        }
        return {
            diaria: formatNumber.format(totalDiaria),
            totalDiaria: formatNumber.format(totalTotalizadorDiaria),
            totalTaxa: formatNumber.format(totalTaxa),
            cartao: formatNumber.format(totalCartao),
            extras: formatNumber.format(totalExtras),
            incentivo: formatNumber.format(totalIncentivo),
            comissao: formatNumber.format(totalComissao),
            desconto: formatNumber.format(totalDesconto),
            fee: formatNumber.format(totalFee),
            liquido: formatNumber.format(totalLiquido),
        };
    }

    return (
        <DataTable 
        header={header}
        size="small" 
        rowGroupFooterTemplate={footerTemplate}
        stripedRows value={servicos}
        tableStyle={{ minWidth: '2250px' }}
        rowGroupMode="subheader" 
        groupRowsBy="grupoServico"
        sortMode="single" 
        sortOrder={1} 
        rowGroupHeaderTemplate={headerTemplate} 
        scrollable 
        scrollHeight="500px">
            <Column style={{ minWidth: '400px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={paxBodyTemplate} header="NOME (S)"></Column>
            <Column style={{ minWidth: '150px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field="categoria" header="PRODUTO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={dataEmissaoBodyTemplate} header="EMISSÃO"></Column>
            <Column style={{ minWidth: '200px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field="numresv" header="IDENTIFICADOR"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={checkinBodyTemplate} header="INÍCIO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={checkoutBodyTemplate} header="TÉRMINO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field="quanpax" header="Nº PAX"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer="Total Geral" headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field="qtddias" header="Nº DIÁRIAS"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer={totalDiaria} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={diariaBodyTemplate} header="DIÁRIA R$"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer={totalTotalizadorDiaria} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={totalDiariasBodyTemplate} header="TOT. DIÁRIAS"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer={totalTaxa} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={taxaBodyTemplate} header="TAXA"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer={totalExtras} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={extrasBodyTemplate} header="EXTRAS"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer={totalCartao} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={cartaoBodyTemplate} header="CARTÃO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer={totalIncentivo} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={incentivoBodyTemplate} header="INCENTIVO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer={totalComissao} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={comissaoBodyTemplate} header="COMISSÃO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer={totalDesconto} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={descontoBodyTemplate} header="DESCONTO"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer={totalFee} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={feeBodyTemplate} header="FEE"></Column>
            <Column style={{ minWidth: '100px' }} footerStyle={{backgroundColor: '#223a77', color: 'white'}} footer={totalLiquido} headerStyle={{ backgroundColor: '#223a77', color: 'white', fontSize:"12px" }} field={liquidoBodyTemplate} header="LÍQUIDO"></Column>
        </DataTable>
    );
}

export default TabelaSeguros;