import React, { useState, useEffect } from 'react';

import SistemaService from '../../api/services/SistemaService';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import TemporaryDrawer from "../../main/TemporayDrawer";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import MuiAlert from '@mui/material/Alert';
import Footer from "../../main/Footer";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import { getCodEmpUser } from '../../api/services/AuthService';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { motion } from "framer-motion";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import styled from "styled-components";

const Layout = styled.div`   
    background-color:white;
    font-weight: bold;
    width:95%;
    height:auto;
    min-height: 550px;
    top:80px;
    margin-top:20px;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    margin-top:100px;
    
`

const StackButton = styled(Stack)`
    font-weight: bold;
    width:80%;
    padding-top:10px;
    padding-bottom:20px;
    align-items: center;
`

const Tabela = styled.div`
  height: 300px;
  width: 100%;
`

const Titulo = styled(Typography)`
    color:#223a77;
    font-weight:bold;
    width:100%;
    margin-botton:30px;
`


const MsgTitulo = styled(Typography)`
    color:#223a77;
    font-weight:bold;
    float: left;
`
const Header = styled(Box)`   
    margin-top:10px;
    width:100%;
    height:50px;
`

const servico = new SistemaService();

const CadastroConfiguracoes = () => {
    const [emails, setEmails] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [email, setEmail] = useState('');
    const [emailObject, setEmailObject] = useState(null);
    const [openSnack, setOpenSnack] = useState(false);
    const [msg, setMsg] = useState('');

    let navigate = useNavigate();

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const columns = [
        {
            field: "Alterar",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return (
                    <Button
                        onClick={(event) => {
                            alterar(cellValues.row)
                        }}><ModeEditIcon /></Button>
                );
            }, width: 100,
        },
        {
            field: "Remover",
            disableColumnMenu: true,
            sortable: false,
            renderCell: (cellValues) => {
                return (
                    <Button
                        onClick={(event) => {
                            handleClickOpenDialog(cellValues.row);
                        }}><DeleteIcon /></Button>
                );
            }, width: 100
        },
        { field: 'descricao', headerName: 'Email', width: '500' },
    ];

    useEffect(() => {
        atualizar();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function handleClose() {
        setOpen(false);
        return open;
    };

    async function handleToggle() {
        setOpen(true);
        return open;
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleClickSnack = () => {
        setOpenSnack(true);
    };

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    const handleClickOpenDialog = (email) => {
        setOpenDialog(true);
        setEmail(email);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const sessionIsActive = () => {
        let codemp = getCodEmpUser();
        if (codemp) {
            return true;
        } else {
            navigate('/login', { state: { expired: true } })
        }
    }

    const atualizar = () => {
        if (sessionIsActive()) {
            handleToggle();
            servico.listar().then(response => {
                setEmails(response.data);
            }).catch(erro => {
                //setError(true);
            }).finally(() => {
                handleClose();
            })
        }
    };

    const salvarEmail = () => {
        if (sessionIsActive()) {
            handleToggle();
            console.log(emailObject);
            if (emailObject) {
                console.log("update");
                handleUpdate();
            } else {
                console.log('save');
                handleSave();
            }
        }
    };

    const handleSave = () => {
        if (sessionIsActive()) {
            setMsg("Email salvo com sucesso!")
            servico.salvar(email).then(response => {
                atualizar();
                handleCloseModal();
                handleClickSnack();
            }).catch(erro => {
                //setError(true);
            }).finally(() => {
                setEmail(null);
                handleClose();
                handleCloseModal();
                setEmailObject(null);
            })
        }
    }

    const excluir = () => {
        if (sessionIsActive()) {
            setMsg("Email excluído com sucesso!")
            servico.deletar({
                idseq: email.idseq
            }).then(response => {
                var newArray = emails.filter((item) => item.idseq !== email.idseq);
                setEmails(newArray);
                setEmail(null);
                handleClickSnack();
            }).catch(erro => {
                console.log(erro);
            }).finally(() => {
                handleCloseDialog();
            })
        }
    };

    const handleUpdate = () => {
        if (sessionIsActive()) {
            setMsg("Email alterado com sucesso!")
            servico.alterar({
                idseq: emailObject.idseq,
                descricao: email
            }).then(response => {
                atualizar();
                handleCloseModal();
                handleClickSnack();
            }).catch(erro => {
                console.log(erro);
            }).finally(() => {
                handleClose();
                handleCloseModal();
                setEmail(null);
                setEmailObject(null);
            })
        }
    }

    const alterar = (email) => {
        if (sessionIsActive()) {
            setEmailObject(email);
            setEmail(email.descricao)
            handleOpenModal();
        }
    }

    return (
        <>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Deseja realmente excluir o email?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Se excluir o email, ele deixará de existir dentro sistema,
                        e não receberá mais notificações geradas por este portal!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>Não</Button>
                    <Button onClick={excluir} autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
            <TemporaryDrawer />
            <motion.div
                className='reembolso'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>
                <Backdrop sx={{ color: '#fff', zIndex: 12000 }} uopen={open}>
                    <CircularProgress style={{ color: '#223a77' }} />
                    <CircularProgress color="inherit" />
                    <CircularProgress style={{ color: '#223a77' }} />
                </Backdrop>
                <Layout className="container">
                    <Header>
                        <Titulo>
                            <MsgTitulo variant="h5" gutterBottom component="div">CONFIGURAÇÕES</MsgTitulo>
                        </Titulo>
                    </Header>
                    <Card sx={{ minWidth: 800, backgroundColor: "#F5F5F5", marginTop: 1, marginBottom: 7 }}>
                        <CardHeader
                            sx={{ height: '20px', paddingLeft: 2, paddingTop: 4, marginBottom: 1 }}
                            titleTypographyProps={{ fontSize: "24px", color: "#223a77" }}
                            title="Cadastro de Emails"
                            avatar={
                                <Avatar variant="square" sx={{ backgroundColor: "#223a77", width: "30px", height: "30px" }} aria-label="recipe">
                                    <AlternateEmailIcon />
                                </Avatar>
                            }
                        >
                        </CardHeader>
                        <CardContent>
                            <StackButton spacing={2} direction="row">
                                <Button startIcon={<SystemUpdateAltIcon />} onClick={atualizar} variant="contained">Atualizar</Button>
                                <Button startIcon={<SaveAltIcon />} onClick={handleOpenModal} variant="contained">Cadastrar</Button>
                            </StackButton>
                            <Tabela>
                                <DataGrid
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    getRowId={(row) => row.idseq}
                                    rows={emails}
                                    columns={columns}
                                    pageSize={5}
                                    rowsPerPageOptions={[5]}
                                />
                            </Tabela>
                        </CardContent>
                    </Card>
                    <Dialog sx={{ width: 1500 }} open={openModal} onClose={handleCloseModal}>
                        <DialogTitle>Novo email para Notificações!</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Insira o email a ser cadastrado!
                            </DialogContentText>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Email"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={email}
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal}>Cancelar</Button>
                            <Button onClick={salvarEmail}>Cadastrar</Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                            {msg}
                        </Alert>
                    </Snackbar>
                </Layout>
            </motion.div>
            <Footer />
        </>

    )
}


export default CadastroConfiguracoes;