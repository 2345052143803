import ApiService from "../api";

class ArquivosService extends ApiService {

    constructor(){
        super('/portal/solicitacao')
    }

    listar(params){
        return this.post('/listarArquivos', params)
    }

    getById(id){
        return this.post('/getArquivo', id)
    }

    getFile(id){
        const params = {
            id: id
        }
        
        return this.get('/files/', params)
    }

}

export default ArquivosService;