import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const BackDrop = (props) => {
    const onOpen = props.onOpen;

    return (
        <Backdrop open={onOpen} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} >
            <CircularProgress style={{ color: '#223a77' }} />
            <CircularProgress color="inherit" />
            <CircularProgress style={{ color: '#223a77' }} />
        </Backdrop>
    )
}

export default BackDrop;