import ApiService from "../api";

class BilheteService extends ApiService{

    constructor(){
        super('/portal/bilhete')
    }

    listar(bilhete){
        return this.post('/getBilhetes', bilhete)
    }
}

export default BilheteService;