import React, { useState } from 'react';
import TemporaryDrawer from "../../main/TemporayDrawer";
import Footer from "../../main/Footer";
import PesquisarBilhetes from './PesquisarBilhetes'
import SolicitarReembolso from './SolicitarReembolso';

const CadastrarReembolso = () => {
 
  const [viewSearchBilhetes, setViewSearchBilhetes] = useState('block');
  const [viewSearchSolicitacao, setViewSearchSolicitacao] = useState('none');
  const [ticketsSelected, setTicketsSelected] = useState([]);
  const [cadastrarCartao, setCadastrarCartao] = useState(false);

  return (
    <>
      <TemporaryDrawer />     
      <PesquisarBilhetes cadastrarCartao={cadastrarCartao} setCadastrarCartao={setCadastrarCartao} viewSearchBilhetes={viewSearchBilhetes} setViewSearchSolicitacao={setViewSearchSolicitacao} setViewSearchBilhetes={setViewSearchBilhetes} selecionados={setTicketsSelected}></PesquisarBilhetes>
      <SolicitarReembolso cadastrarCartao={cadastrarCartao} setCadastrarCartao={setCadastrarCartao} viewSearchSolicitacao={viewSearchSolicitacao} setViewSearchSolicitacao={setViewSearchSolicitacao} setViewSearchBilhetes={setViewSearchBilhetes} selecionados={ticketsSelected}></SolicitarReembolso>
      <Footer />
    </>
  );
};

export default CadastrarReembolso;