import ApiService from "../api";

const firstCheck = "Estou ciente que o reembolso será recusado, caso a reserva não esteja cancelada e " +
"o número do cartão de crédito não seja informado na solicitação (para emissões internacionais). " + 
"A obrigatoriedade do numero do cartão é uma exigência da Cia aérea.";
    

const secondCheck = "Estou ciente que reembolsos 24 horas deverão ser obrigatoriamente encaminhados " +
    "para o email reembolso24h@grupobrt.com.br"

class ReembolsoService extends ApiService {

    static get firstCheck(){
        return firstCheck;
    }

    static get secondCheck(){
        return secondCheck;
    }

    constructor(){
        super('/portal/solicitacao')
    }

    salvar(solicitacao){
        return this.post('/saveSolicitacao', solicitacao)
    }

    listar(filtro){
        return this.post('/listarSolicitacoes', filtro)
    }

    enviarEmails(solicitacoes){
        return this.post('/sendEmail', solicitacoes)
    }

    pegarUsuarioSolicitacao(codemp){
        return this.post("/userSolicitacao", codemp)
    }
}

export default ReembolsoService;