import ApiService from "../api";

class FaturaService extends ApiService{

    constructor(){
        super('/portal/fatura')
    }

    listar(filtro){
        return this.post('/listarFaturas', filtro)
    }

    detalhar(filtro){
        return this.post('/detalharFatura', filtro)
    }

    clearStorage() {
        sessionStorage.setItem("numeroFatura", '');
        sessionStorage.setItem("codigoEmpresaSelecionada", '');
        sessionStorage.setItem("empresa", '');
        sessionStorage.setItem("dataInicio", null);
        sessionStorage.setItem("dataFim", null);
        sessionStorage.setItem("tipoData", 'TODAS');
        sessionStorage.setItem("pagamento", 'AMBOS');
        sessionStorage.setItem("stored", false);
    }
}

export default FaturaService;